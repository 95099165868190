import React, { useState, useMemo } from "react";

import { AnimatePresence } from "moti";

import { BottomSheetModalProvider } from "@showtime-xyz/universal.bottom-sheet";
import { View } from "@showtime-xyz/universal.view";

import { useUser } from "app/hooks/use-user";

import { OnboardingStepContext } from "./onboarding-context";
import { OnboardingStep } from "./onboarding-types";
import { SelectSocial } from "./select-social";
import { SelectPreferences } from "./select-preferences";

export const Onboarding = () => {
  const { user } = useUser();

  // determine initial step
  const initialStep = useMemo(() => {
    // if (__DEV__) {
    //   return OnboardingStep.Username;
    // }

    // // this is autogenerated username, so we show the username step
    // if (user?.data?.profile?.username?.startsWith("projectx")) {
    //   return OnboardingStep.Username;
    // }

    if (!user?.data?.profile?.location_code) {
      return OnboardingStep.Preferences;
    }

    if (user?.data?.profile?.location_code) {
      return OnboardingStep.Social;
    }

    // if (user?.data?.profile.username) {
    //   return OnboardingStep.Picture;
    // }

    // if (user?.data?.profile.username) {
    //   return OnboardingStep.Picture;
    // }

    // if (user?.data?.profile.img_url) {
    //   return OnboardingStep.Social;
    // }


    return OnboardingStep.Preferences;
  }, [user]);
  const [step, setStep] = useState<OnboardingStep>(initialStep);
  const value = useMemo(() => ({ step, setStep, user }), [step, user]);

  return (
    <OnboardingStepContext.Provider value={value}>
      <BottomSheetModalProvider>
        <View tw="mt-8 flex-1">
          <AnimatePresence >
            {/* {step === OnboardingStep.Username && (
              <SelectUsername key="username" />
            )}
            {step === OnboardingStep.Picture && <SelectPicture key="picture" />} */}
            {step === OnboardingStep.Preferences && <SelectPreferences key="preferences" />}
            {step === OnboardingStep.Social && <SelectSocial key="social" />}
          </AnimatePresence>
        </View>
      </BottomSheetModalProvider>
    </OnboardingStepContext.Provider>
  );
};
